@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

body {
  height: 100%;
  margin: 0;
  background-color: #fafafa;
  font-family: "Inter", sans-serif;
}

html {
  height: 100%;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

/* Mainframe */
.mainframe {
  position: relative;
  z-index: 1;
  flex: 1;
  flex-grow: 1;
  width: 990px;
  height: 704px;
  left: 50%;
  margin-top: 80px;
  background: #01070a;
  border-radius: 40px;
  transform: translateX(-50%);

  overflow: hidden;
}

/* HERO */
.hero {
  position: absolute;
  width: 990px;
  height: 520px;
  left: calc(50% - 990px / 2);
  top: 0;
  background: url("../public/RINS_BG.jpg") no-repeat center center, #fcfcfc;
}

/* logo */
.logo {
  position: absolute;
  width: 54px;
  height: 16px;
  left: 80px;
  top: 83px;
  background: url("../public/RINS_LOGO.svg") no-repeat;
}

/* Sample copy */
.arriving {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  position: absolute;
  left: 80px;
  top: 95px;
  color: #01070a;
}

/* Landingpage 2023 */
.landingpage-2023 {
  position: absolute;
  width: 100%;
  height: 30px;
  left: 80px;
  top: 374px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: #ffffff;
}

/* Subtitle example text */
.subtitle-example-text {
  position: absolute;
  width: 100%;
  height: 28px;
  left: 80px;
  top: 428px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #e5f4f9;
}

/* FORM */
.form {
  position: absolute;
  width: 893px;
  height: 68px;
  left: 80px;
  top: 576px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

/* input_email */
.input_email {
  width: 360px;
  height: 68px;
  background: #181c1e;
  border: none;
  border-radius: 4px;
  color: #e5f4f9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 0 16px;
  outline: none;
}

/* Update the input email styles to make the text have the same attributes as its mask */
.input_email::placeholder {
  color: #e5f4f9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

/* input_select */
.input_select {
  width: 250px;
  height: 68px;
  background: #181c1e;
  border: none;
  border-radius: 4px;
  color: #e5f4f9;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 0 16px;
  outline: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../public/icon_PLUS.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 12px);
  background-position-y: calc(50% - 1px);
  padding-right: 16px;
}

/* Remove all borders from input and select */
.input_email:focus,
.input_select:focus {
  outline: none;
}

.input_email:hover,
.input_select:hover {
  background-color: #2c3539;
}

/* submit button — Join the waitlist */
.submit-button {
  width: 251px;
  height: 68px;
  background: none;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-button::before {
  content: "";
  position: absolute;
  right: auto;
  bottom: 20px; /* Adjust this value to change the offset */
  width: 136px;
  height: 1px;
  background-color: #ffffff;
}

/* Add a hover effect for the submit button */
.submit-button:hover {
  color: #ccdde4; /* 10% darker text */
}

.submit-button:hover::before {
  background-color: #ccdde4;
  opacity: 30%;
}

.submit-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* error-message */
.error-message {
  font-family: "Inter", sans-serif;
  position: absolute;
  left: 80px;
  top: 524px;
  font-size: 14px;
  color: #ffc800;
}

/* Email input container */
.email-input-container {
  position: relative;
}

/* Mask inside email input */
.mask-text {
  position: absolute;
  width: 18px;
  height: 28px;
  left: 32px;
  top: 20px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #e5f4f9;
}

/* Invalid email input */

.select-container {
  position: relative;
}

/* Chevron Icon SVG */
.chevron-icon {
  display: none;
}

/* Invalid email input */
.invalid-email {
  outline: 2px solid #ffc700;
  outline-offset: 2px;
}

/* Invalid select input */
.invalid-select {
  outline: 2px solid #ffc700;
  outline-offset: 2px;
}

.form.success-message {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: normal;
  color: #ffffff;
  justify-content: left;
  position: relative;
  gap: 12px; /* Adjust the gap between the checkmark and the text */
  flex: 1;
}

.checkmark-icon {
  width: 24px;
  height: 24px;
  background-image: url("../public/icon_CHECKMARK.svg");
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(1);
  animation: bounce 1s;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 0;
  box-sizing: border-box;
}

.footer-logo {
  width: 74px;
  height: 22px;
  background: url("../public/RINS_LOGO_Footer.svg") no-repeat;
}

.footer-we-are {
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2c3539;
  margin-top: 16px;
}

.footer-links {
  display: flex;
  gap: 16px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #2c3539;
  margin-top: 44px;
  flex: 1;
}

.footer-info-link {
  text-decoration: underline;
}

.privacy-link {
  color: #2c3539;
  text-decoration: underline;
}

.privacy-link:hover {
  text-decoration: none;
}

/* Add this media query to your CSS file */
@media screen and (max-width: 979px) {
  .mainframe {
    width: 320px;
    margin: 80px auto;
  }

  .hero {
    width: 100%;
    height: 396px;
    background-image: url("../public/RINS_BG_Mobile.jpg"); /* Replace with the correct file path */
    background-size: cover;
    background-position: center;
  }

  .logo,
  .arriving,
  .landingpage-2023,
  .subtitle-example-text {
    position: static;
    display: block;
    margin-left: 24px;
    margin-right: 24px;
  }

  .form {
    position: static;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }

  .input_email,
  .input_select,
  .submit-button {
    box-sizing: border-box;
    width: calc(100% - 48px);
    margin-bottom: 1rem;
  }

  .input_email,
  .input_select {
    padding-left: 24px;
    padding-right: 24px;
  }

  .submit-container {
    width: calc(100% - 48px);
  }

  .submit-button {
    width: 100%;
  }

  .error-message {
    position: static;
    margin-top: 1rem;
  }
}

/* Mobile CSS */
@media (min-width: 320px) and (max-width: 979px) {
  .pageContainer {
    color: pink;
  }

  .mainframe {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    margin-top: 32px;
    margin-left: 0px;
    margin-bottom: 0px;
    border-radius: 20px;
  }

  .hero {
    position: initial;
    width: 100%;
    height: 396px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-image: url("../public/RINS_BG_Mobile.jpg"); /* Adjust the path according to your folder structure */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 60px;
    left: 0;
  }

  .logo,
  .arriving {
    margin-left: 32px;
  }

  .arriving {
    margin-top: 12px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 296px;
    height: auto;
  }

  .input_email,
  .input_select {
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
  }

  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .submit-button::before {
    bottom: 56px;
  }

  .landingpage-2023 {
    width: auto;
    height: auto;
    margin-top: 60px;
    margin-bottom: 0px;
    line-height: 36px;
  }

  .subtitle-example-text {
    width: auto;
    height: auto;
    line-height: 24px;
    font-size: 16px;
  }

  .error-message {
    width: 304px;
    font-size: 12px;
    position: absolute;
    margin-left: 8px;
    padding-left: 16px;
    left: 0;
    top: 354px;
  }

  .form.success-message {
    top: 140px;
    left: 0px;
    width: 140px;
    text-align: center;
    line-height: 24px;
  }
}
